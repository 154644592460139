import { useEffect, useRef } from 'react';

const useOnce = (
  predicate = false,
  callback = Function.prototype,
  deps = [],
) => {
  const triggered = useRef(false);

  useEffect(() => {
    if (predicate && !triggered.current) {
      triggered.current = true;
      callback();
    }
  }, [...deps, predicate, callback]);
};

export default useOnce;
