import config, { DEFAULT_DEMO_HOST } from 'hostsConfig';

const currentHost = window.location.host;

let innerAppHost = DEFAULT_DEMO_HOST;
if (config.has(currentHost)) {
  const innerHost = config.get(currentHost);
  let protocol = 'https';
  if (innerHost.indexOf('localhost') > -1) {
    protocol = 'http';
  }
  innerAppHost = `${protocol}://${innerHost}`;
}

export const prepareUrl = (lang, path = '') => {
  return innerAppHost + `/${lang}` + path;
};
