import { isBetterHost } from 'hostsConfig';

export const beforeAppRender = () => {
  document.querySelector('#page-title').innerHTML = isBetterHost
    ? 'BETER iFrame Demo'
    : 'GR8Tech Iframe demo brand';
  document.querySelector('#favicon').href = isBetterHost
    ? '/better_favicon.png'
    : '/favicon.ico';
};
