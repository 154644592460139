import auth from './auth';

let prevPath = '/';

export const blacklistedRoutes = ['/mini-iframe'];

const postMessage = (value, type = 'iframe') => {
  try {
    window.frames.target.postMessage(
      {
        type,
        value,
      },
      '*', // @todo use config.get(currentHost) later
    );
  } catch (e) {
    console.error(e);
  }
};

/** this handlers should be overrided by clients code */
const defaultHandlers = {
  loginButtonClick: () => {
    console.log('login button clicked');
  },
  authFailedCallback: () => {
    alert('Something went wrong');
  },
  refreshBalanceCallback: (data) => {
    console.log('balance refresh', data);
  },
  navigationCallback: (data) => {
    console.log('navigation', data);
  },
  logoutCallback: () => {
    console.log('logout');
  },
};

const listenMessages = (inputHandlers) => {
  const handlers = { ...defaultHandlers, ...inputHandlers };
  const handleMessage = ({ data }) => {
    if (data.type === 'login.click') {
      handlers.loginButtonClick();
    }
    if (data.type === 'auth.fail') {
      handlers.authFailedCallback();
    }
    if (data.type === 'user.balance') {
      handlers.refreshBalanceCallback();
    }
    if (data.type === 'navigation') {
      handlers.navigationCallback(data);
    }
    if (data.type === 'logout') {
      handlers.logoutCallback();
    }
  };

  const unsubscribeCallback = () => {
    window.removeEventListener('message', handleMessage);
    listenMessages.subscribed = false;
  };

  if (listenMessages.subscribed)
    return {
      unlistenMessages: unsubscribeCallback,
    };

  window.addEventListener('message', handleMessage);
  listenMessages.subscribed = true;

  return {
    unlistenMessages: unsubscribeCallback,
  };
};

const integrationLayer = (path) => {
  if (path) prevPath = path;

  return {
    listenMessages,
    postMessage,
    reload: () => {
      window.location.reload();
    },
    logout: async () => {
      localStorage.removeItem('token');
      postMessage({ logout: true });
      await auth.logout();
    },
    login: (data) => auth.login(data),
    getLocalToken: () => localStorage.getItem('token'),
    getUser: async () => {
      const token = localStorage.getItem('token');
      return auth.userInfo(token);
    },
    getJwt: async (a, b) => {
      return await auth.getJwt(a, b);
    },
    prevPath,
  };
};

export default integrationLayer;
