import { useEffect, useState } from 'react';
import styles from './LoginPage.module.css';
import integrationLayer from 'services/integration';
import useNavigation, { getQueryString } from 'hooks/useNavigation';
import { useLanguageService } from 'translations';
import { prepareUrl } from 'helpers';
import Header from 'components/Header/Header';

const WidgetPage = () => {
  const { lang, langCodes } = useLanguageService();
  const { navigate } = useNavigation(lang, langCodes);
  const [query, setQuery] = useState();

  useEffect(() => {
    const integration = integrationLayer('/widget');
    const { unlistenMessages } = integration.listenMessages({
      navigationCallback: ({ path, query }) => {
        if (path === '/mini-iframe') return;
        const fullPath = `${path}${getQueryString(query, 'jwt')}`;
        navigate({ path: fullPath }, false);
      },
    });
    let token = integration.getLocalToken();
    const query = new URLSearchParams({
      ...(token ? { jwt: token } : {}),
    });
    setQuery(query.toString());

    return () => {
      unlistenMessages();
    };
  }, [navigate]);

  return (
    <div className={styles.widget}>
      <Header hideControls />
      <iframe
        width="50%"
        height="50%"
        name="target"
        id="betbook"
        frameBorder="0"
        className="betbook"
        src={prepareUrl('') + 'mini-iframe?' + query}
        title="stage"
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default WidgetPage;
