import { useCallback, useState, useRef, useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import { useUser } from './components/UserProvider/UserProvider';
import { useLanguageService } from './translations';
import useNavigation, {
  getQueryString,
  getUrlLang,
} from './hooks/useNavigation';
import { prepareUrl } from 'helpers';
import integrationLayer, { blacklistedRoutes } from 'services/integration';
import useOnce from "./hooks/useOnce";

function App() {
  const { lang, setLang, langCodes } = useLanguageService();
  const [currency, setCurrency] = useState();
  const [isLogoutInFlight, setIsLogoutInFlight] = useState(false);
  const {
    user,
    setUser,
    isAuthPending,
    setIsAuthPending,
    refreshBalance,
    setRefreshBalance,
  } = useUser();
  const userRef = useRef({});
  const [sdk, setSdk] = useState({});
  const { navigate, navigateByPath, iframePath, clientPath, search } =
    useNavigation(lang, langCodes);
  const routerRef = useRef({ prevPath: iframePath, prevSearch: search });
  const [url, setUrl] = useState(prepareUrl(lang, '/'));
  const [isRouterReady, setIsRouterReady] = useState(false);
  const iframeUrlLang = getUrlLang(url, langCodes);
  const clientUrlLang = getUrlLang(clientPath, langCodes);

  const logout = useCallback(async () => {
    await sdk.logout();
    setUser(null);
  }, [setUser, sdk]);

  const login = useCallback(() => {
    navigateByPath('/login');
  }, [navigateByPath]);

  useEffect(() => {
    if( iframeUrlLang !== lang ) {
      setLang(lang);
      setUrl(prepareUrl(lang, routerRef.current.prevPath) );
    }
    clientUrlLang !== lang && navigate({ path: iframePath, language: lang});
  }, [clientUrlLang, iframePath, iframeUrlLang, lang, navigate, setLang]);

  useOnce(sdk?.postMessage && routerRef.current?.prevPath && isRouterReady, ()=> {
    const { prevPath } = routerRef.current;
    let path = prevPath;
    if (blacklistedRoutes.includes(prevPath)) {
      path = '/';
    }
    sdk.postMessage({ path }, 'iframe-navigation');
  })

  useEffect(() => {
    const { prevPath } = routerRef.current;
    const integration = integrationLayer(prevPath);
    const { unlistenMessages } = integration.listenMessages({
      loginButtonClick: () => {
        unlistenMessages();
        login();
      },
      authFailedCallback: () => {
        alert('Something went wrong');
      },
      refreshBalanceCallback: () => {
        setRefreshBalance((v) => v + 1);
      },
      navigationCallback: ({ path, query, previousPath }) => {
        if ( !previousPath && !isRouterReady ) {
          return setIsRouterReady(true);
        }
        const fullPath = `${path}${getQueryString(query, 'jwt')}`;
        if (fullPath === routerRef.current.prevPath) return;
        navigate({ path: fullPath });
        routerRef.current.prevPath = fullPath;
      },
      logoutCallback: () => {
        setUser(null);
        userRef.current.loggedOut = true;
      },
    });
    setSdk(integration);
  }, [login, navigate, setUser, isRouterReady, lang, setRefreshBalance]);

  const checkUser = useCallback(async () => {
    if (!sdk.getUser) return;
    if (localStorage.getItem('token')) {
      const user = await sdk.getUser();
      setUser(user);
      sdk.postMessage({ balance: user?.balance, currency: user?.currency });
    }
  }, [setUser, sdk]);

  useEffect(() => {
    if (!user && userRef.current.loggedOut) {
      logout();
    }
  }, [logout, user]);

  useEffect(() => {
    if (user?.jwt) {
      const { prevSearch } = routerRef.current;
      const query = prevSearch ? prevSearch.replace('?', '&') : '';
      setUrl((url) => {
        const data = new URL(url);
        const search = new URLSearchParams({
          jwt: user.jwt.jwt,
        });
        return data.origin + data.pathname + '?' + search.toString() + query;
      });
      setIsAuthPending(false);
    }
  }, [setIsAuthPending, user?.jwt, lang, search, routerRef, setUrl]);

  useEffect(() => {
    checkUser();
  }, [refreshBalance, checkUser]);

  const changeCurrency = (currency) => {
    setIsLogoutInFlight(true);
    sdk.postMessage({ logout: true });
    setCurrency(currency);
  };

  const onLoadCallback = () => {
    if (isLogoutInFlight) {
      sdk.getJwt(user.authToken, currency).then(({ jwt }) => {
        setUser((user) => ({ ...user, jwt: { jwt } }));
        setIsLogoutInFlight(false);
      });
    }
  };

  const refreshCallback = () => {
    navigateByPath('/');
    sdk.reload();
  };

  if (!url || isAuthPending) {
    return null;
  }

  return (
    <div className="App">
      <Header
        currency={currency}
        user={user}
        login={login}
        logout={logout}
        refresh={refreshCallback}
        updateLang={setLang}
        changeCurrency={changeCurrency}
      />
      <iframe
        onLoad={onLoadCallback}
        name="target"
        id="betbook"
        frameBorder="0"
        className="betbook"
        src={url}
        title="stage"
        allow="autoplay"
      ></iframe>
    </div>
  );
}

export default App;
