import { useMemo } from 'react';
import Button from '../Button/Button';
import { useEffect } from 'react';
import { isBetterHost, isProdHost } from '../../hostsConfig';
import Select from '../Select/Select';
import { useLanguageService } from '../../translations';
import useViewMode from '../../hooks/useViewMode';

const currencyMapping = {
  UAH: '₴',
  EUR: '€',
  USD: '$',
  AZN: 'AZN',
  XTS: 'XTS',
};

const currencies = [
  { name: 'USD', alias: 'USD' },
  { name: 'EUR', alias: 'EUR' },
  { name: 'AZN', alias: 'AZN' },
  { name: 'XTS', alias: 'XTS' },
];

function Header({
  refresh = () => {},
  user,
  logout,
  login,
  updateLang = () => {},
  changeCurrency,
  currency = 'USD',
  hideControls,
}) {
  const { t, setLang, lang, languages } = useLanguageService();

  const { isWideView } = useViewMode();

  useEffect(() => {
    updateLang(lang);
  }, [lang, updateLang]);

  const activeCurrency = useMemo(() => {
    const activeCurrency = currencies.find(({ name }) => name === currency);
    return activeCurrency;
  }, [currency]);

  const showCurrencySelect = !isProdHost && isWideView;

  return (
    <header className={`App-header ${isBetterHost ? 'beter' : ''}`}>
      <img
        alt="logo"
        width={isBetterHost ? 75 : 113}
        height={28}
        className="logo header-item"
        src={
          isBetterHost ? '/beter_esports_primary_logo.png' : '/logo-large.svg'
        }
        onClick={refresh}
      />
      {!hideControls && (
        <>
          {user && (
            <div className="header-item balance right">
              {showCurrencySelect && (
                <Select
                  items={currencies}
                  activeItem={activeCurrency}
                  onSelect={({ name }) => {
                    changeCurrency(name);
                  }}
                />
              )}
              <Select
                items={languages}
                activeItem={languages.find(({ name }) => name === lang)}
                onSelect={(data) => {
                  setLang(data.name);
                }}
                withImage={isWideView}
              />
              <div className="balance-wrapper">
                <span className="balance-title">{t('Balance')}</span>
                <span className="balance-count">
                  {currencyMapping[currency]} {user?.balance}
                </span>
              </div>

              <Button
                action={logout}
                type={isBetterHost ? 'additional' : 'primary'}
              >
                {t('Logout')}
              </Button>
            </div>
          )}
          {!user && (
            <div className="header-item right">
              <Select
                items={languages}
                activeItem={languages.find(({ name }) => name === lang)}
                onSelect={(data) => {
                  setLang(data.name);
                }}
                withImage={isWideView}
              />
              <Button action={login} type={isBetterHost && 'secondary'}>
                {t('Log in')}
              </Button>
            </div>
          )}
        </>
      )}
    </header>
  );
}

export default Header;
