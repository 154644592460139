import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import styles from './LoginForm.module.css';
import Input from '../Input/Input';
import { useNavigate } from 'react-router-dom';
import {
  isBetterHost,
  isIframeEuHost,
  isIframeAsiaHost,
  isIframeLatamHost,
  isIframeIndiaHost,
  isIframeTurkeyHost,
} from '../../hostsConfig';
import { useLanguageService } from '../../translations';

const USERNAMES = {
  BETER: 'beter',
  EU: 'iframe-eu',
  INDIA: 'iframe-india',
  TURKEY: 'iframe-turkey',
  ASIA: 'iframe-asia',
  LATAM: 'iframe-latam',
  DEFAULT: 'gr8-iframe-demo-user',
};
const getUserName = () => {
  switch (true) {
    case isBetterHost:
      return USERNAMES.BETER;
    case isIframeEuHost:
      return USERNAMES.EU;
    case isIframeAsiaHost:
      return USERNAMES.ASIA;
    case isIframeLatamHost:
      return USERNAMES.LATAM;
    case isIframeIndiaHost:
      return USERNAMES.INDIA;
    case isIframeTurkeyHost:
      return USERNAMES.TURKEY;
    default:
      return USERNAMES.DEFAULT;
  }
};
let user = getUserName();
let pass = isBetterHost ? 'iframe' : 'gr8-tech';

const LoginForm = ({ login, error, onChange = () => {} }) => {
  const [username, setLogin] = useState(user);
  const [password, setPassword] = useState(pass);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const { t } = useLanguageService();
  useEffect(() => {
    if (error || !username || !password) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [username, password, error]);

  useEffect(() => {
    onChange({ password, username });
  }, [password, username, onChange]);

  return (
    <div className={`${styles.wrapper} ${isBetterHost ? styles.beter : ''}`}>
      <div className={styles.header}>
        <svg
          onClick={() => navigate('/')}
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 16C7.74 16 7.47 15.9 7.27 15.69C5.45 13.77 0 8 0 8C0 8 5.45 2.23 7.27 0.309999C7.47 0.0999995 7.73 0 8 0C8.55 0 9 0.45 9 1C9 1.25 8.91 1.49 8.73 1.69C7.24 3.27 2.75 8 2.75 8C2.75 8 7.23 12.73 8.73 14.31C8.91 14.5 9 14.75 9 15C9 15.55 8.55 16 8 16Z"
            fill="#D0D3F0"
          />
        </svg>
        <p>{t('Log in')}</p>
      </div>
      <div className={styles.welcome}>
        <h2>{t('Welcome back')}</h2>
        <p>{t('Greeting')}</p>
      </div>
      <div className={styles.row}>
        <Input
          error={error}
          placeholder={t('Username')}
          value={username}
          type="text"
          onChange={setLogin}
        />
      </div>

      <div className={styles.row}>
        <Input
          error={error}
          placeholder={t('Password')}
          value={password}
          type="password"
          onChange={setPassword}
        />
      </div>
      {error && (
        <p className={`${styles['simple-row']} ${styles.error}`}>{error}</p>
      )}
      <div className={styles.row}>
        <Button
          disabled={disabled}
          type={isBetterHost ? 'beter-action' : 'action'}
          action={() => {
            login({ login: username, password });
          }}
        >
          {t('Log in')}
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
