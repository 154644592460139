import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const IFRAME_PREFIX = 'sports';

/**
 * Extracts the language from the given path.
 *
 * @param {string} path - The URL path.
 * @param {Array} languages - Array of available languages.
 * @returns {string|undefined} - The language code if found, otherwise undefined.
 */
export const getUrlLang = (path, languages) => {
  return path.split('/').find((segment) => languages.includes(segment));
};

/**
 * Converts query objects into query string.
 *
 * @param {Object} params - Object with search parameters.
 * @param {string} exclude - Parameter to exclude from the query string.
 * @returns {string} - Query string with search parameters or empty string.
 */
export const getQueryString = (params, exclude = '') => {
  const queryString = Object.keys(params)
    .filter((key) => key !== exclude)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
    )
    .join('&');
  return queryString.length ? '?' + queryString : '';
};

/**
 * Prepares the iframe path by removing language and prefix segments.
 *
 * @param {string} path - The URL path.
 * @param {Array} languages - Array of available language codes.
 * @param {string} fallbackLang - The default language code.
 * @returns {string} - The prepared iframe path.
 */
const prepareIframePath = (path, languages, fallbackLang) => {
  const lang = getUrlLang(path, languages) || fallbackLang;
  const regex = new RegExp(
    `${IFRAME_PREFIX}/${lang}/?|/${lang}/?`,
    'g',
  );
  return path.replace(regex, '');
};

/**
 * Prepares the client path by concatenating prefix, language and iframe path segments.
 *
 * @param {string} path - The iframe path.
 * @param {Array} languages - Array of available language codes.
 * @param {string} fallbackLang - The default language code.
 * @returns {string} - The prepared client path.
 */
const prepareClientPath = (path, languages, fallbackLang) => {
  const lang = getUrlLang(path, languages) || fallbackLang;
  const preparedIframePath = prepareIframePath(path, languages, fallbackLang);
  return preparedIframePath === '/'
    ? preparedIframePath
    : `/${IFRAME_PREFIX}/${lang}${preparedIframePath}`;
};

/**
 * Custom hook for client-iframe navigation.
 *
 * @param {string} lang - The current language code.
 * @param {Array} langList - Array of supported language codes.
 * @returns {Object} - Object containing navigation functions and paths.
 */
export const useNavigation = (lang, langList) => {
  const navigateByPath = useNavigate();
  const { pathname, search } = useLocation();
  const iframePath = prepareIframePath(`${pathname}${search}`, langList, lang);
  const clientPath = prepareClientPath(`${pathname}${search}`, langList, lang);
  /**
   * Navigates by path.
   *
   * @param {Object} options - Navigation options.
   * @param {string} options.path - The path to navigate to.
   * @param {string} [options.language=lang] - The language code to use.
   */
  const navigate = useCallback(
    ({ path, language = lang }, replace = true) => {
      navigateByPath(prepareClientPath(path, langList, language), { replace });
    },
    [lang, navigateByPath, langList],
  );

  return {
    navigateByPath,
    navigate,
    iframePath,
    clientPath,
    search,
  };
};

export default useNavigation;
