import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import UserProvider from './components/UserProvider/UserProvider';
import WidgetPage from './pages/Widget';
import { beforeAppRender } from 'hacks';

const router = createBrowserRouter([
  {
    path: 'widget',
    element: <WidgetPage />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <App />,
  },
]);

beforeAppRender();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
